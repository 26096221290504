<script setup>
import { ref } from "vue";
import { translate } from './translations.js';
import image1 from "../assets/11 Interior.jpg";
import image2 from "../assets/dr-michel-cohen.jpg";
import image3 from "../assets/18 Portrait.jpg";
import image4 from "../assets/01 Aerial 1.jpg";
import image5 from "../assets/13 Interior.jpg";
import image6 from "../assets/09 Interior.jpg";
import image7 from "../assets/07 Interior.jpg";
import image8 from "../assets/08 Interior.jpg";
import HeaderImage from "../assets/Header.jpg";

const img1 = ref(image1);
const img2 = ref(image2);
const img3 = ref(image3);
const img4 = ref(image4);
const img5 = ref(image5);
const img6 = ref(image6);
const img7 = ref(image7);
const img8 = ref(image8);
const Header = ref(HeaderImage);
</script>
<template>


    <div class="hidden sm:block our-purpose-separation">
    
        <div class="flex bg-customYellow items-center flex-wrap">
    
            <div class="w-full md:w-6/12">
    
                <div class="section-text-container">
    
                    <h1 class="text-4xl font-bold text-white">{{translate('services')}}</h1>
    
                    <p class="mt-4 text-[25px] leading-7 text-white font-normal">
    
                        <ul class="list-disc ml-3 text-left">
    
                            <li class="text-white mb-1 font-light">{{translate('same_day_sick_visits')}}</li>
    
                            <li class="text-white mb-1 font-light">{{translate('quick_scheduling_followup_visits')}}</li>
    
                            <li class="text-white mb-1 font-light">{{translate('night_time')}}</li>
        
                            <li class="text-white mb-1 font-light">{{translate('on_site_testing')}}</li>

                            <li class="text-white mb-1 font-light">{{translate('convenient_payment_methods')}}</li>

    
                        </ul>
    
                    </p>
    
                    <a class="text-white underline text-xl d-block mt-4 font-normal">
    
                        <router-link to="/FAQs">{{translate('read_our_faqs')}}</router-link>
    
                    </a>
    
                </div>
    
            </div>
    
            <div class="w-full md:w-6/12 mt-3 section-right-image">
    
                <img :src="img1" alt="" class="w-100">
    
            </div>
    
        </div>
    
    </div>
    
    <div class="block sm:hidden">
    
        <div class="bg-customYellow items-center flex-wrap">
    
            <div class="w-full md:w-6/12">
    
                <div class="section-text-container">
    
                    <h1 class="text-4xl font-bold text-white home-text-align">{{translate('services')}}</h1>
    
                    <div class="section-right-image">
    
                        <img :src="img1" alt="" class="w-100">
    
                    </div>
    
                    <div class="content-last">
    
                        <p class="mt-4 text-[25px] leading-7 text-white font-normal">
    
                            <ul class="list-disc ml-3 text-left">
    
                                <li class="text-white mb-1 font-light">{{translate('same_day_sick_visits')}}</li>
    
                                <li class="text-white mb-1 font-light">{{translate('quick_scheduling_followup_visits')}}</li>
    
                                <li class="text-white mb-1 font-light">{{translate('night_time')}}</li>
        
                                <li class="text-white mb-1 font-light">{{translate('on_site_testing')}}</li>

                                <li class="text-white mb-1 font-light">{{translate('convenient_payment_methods')}}</li>

    
                            </ul>
    
                        </p>
    
                        <a class="text-white underline text-xl d-block mt-4 font-normal">{{translate('read_our_faqs')}}</a>
    
                    </div>
    
                </div>
    
            </div>
    
        </div>
    
    </div>    
    
    
    <div class="hidden sm:block">
    
        <div class="flex bg-customGreen items-center flex-wrap">
    
            <div class="w-full md:w-6/12 mt-3 self-start md:self-auto mt-3 section-left-image">
    
                <img :src="img2" alt="" class="w-100">
    
            </div>
    
            <div class="w-full md:w-6/12 section-right-text">
    
                <div class="section-text-container">
    
                    <h1 class="text-3xl font-bold text-white">{{translate('who_are_we')}}...</h1>
    
                    <p class="text-[20px] leading-6 text-white font-light mt-4 text-justify ">{{translate('founder_information')}}</p>
    
                    <a class="text-white underline text-xl d-block mt-4">
    
                        <router-link to="/Our-Story">{{translate('read_more')}}</router-link>
    
                    </a>
    
                </div>
    
            </div>
    
        </div>
    
    </div>
    
    <div class="block sm:hidden">
    
        <div class="bg-customGreen items-center flex-wrap">
    
            <div class="w-full md:w-6/12">
    
                <div class="section-text-container">
    
                    <h1 class="text-3xl font-bold text-white">{{translate('who_are_we')}}...</h1>
    
                    <div class="section-left-image">
    
                        <img :src="img2" alt="" class="w-100">
    
                    </div>
    
                    <div class="content-last">
    
                        <p class="text-[20px] text-white font-light mt-4 text-justify ">{{translate('founder_information')}}</p>
    
                        <a class="text-white underline text-xl d-block mt-4">
    
                            <router-link to="/Our-Story">{{translate('read_more')}}</router-link>
    
                        </a>
    
                    </div>
    
                </div>
    
            </div>
    
        </div>
    
    </div>
    
    <div class="hidden sm:block">
    
        <div class="flex bg-customBrown items-center flex-wrap">
    
            <div class="w-full md:w-6/12">
    
                <div class="section-text-container">
    
                    <h1 class="text-3xl font-bold text-white title-first">{{translate('whats_our_purpose')}}</h1>
    
                    <div class="content-last">
    
                        <p class="mt-4 text-[20px] leading-6 text-white font-normal text-justify">{{translate('vision')}}</p>
    
                        <p class="text-[20px] leading-6 text-white font-light text-justify">{{translate('provide_access_to_pediatric_care')}}</p>
    
                        <p class="mt-4 text-[20px] leading-6 text-white font-normal text-justify">{{translate('mission')}}</p>
    
                        <p class="text-[20px] leading-6 text-white font-light text-justify">{{translate('establish_network_pediatric_clinics')}}</p>
    
                    </div>
    
                </div>
    
            </div>
    
            <div class="w-full md:w-6/12 mt-3 self-start md:self-auto section-right-image image-between">
    
                <img :src="img7" alt="" class="w-100">
    
            </div>
    
        </div>
    
    </div>
    
    <div class="block sm:hidden our-purpose-separation">
    
        <div class="bg-customBrown items-center flex-wrap">
    
            <div class="w-full md:w-6/12">
    
                <div class="section-text-container">
    
                    <h1 class="text-3xl font-bold text-white title-first home-text-align">{{translate('whats_our_purpose')}}</h1>
    
                    <div class="section-right-image">
    
                        <img :src="img7" alt="" class="w-100">
    
                    </div>
    
                    <div class="content-last">
    
                        <p class="mt-4 text-[20px] leading-6 text-white font-normal text-justify">{{translate('vision')}}</p>
    
                        <p class="text-[20px] leading-6 text-white font-light text-justify">{{translate('provide_access_to_pediatric_care')}}</p>
    
                        <p class="mt-4 text-[20px] leading-6 text-white font-normal text-justify">{{translate('mission')}}</p>
    
                        <p class="text-[20px] leading-6 text-white font-light text-justify">{{translate('establish_network_pediatric_clinics')}}</p>
    
                    </div>
    
                </div>
    
            </div>
    
        </div>
    
    </div>
    
    <div class="hidden sm:block">
    
        <div class="flex bg-customPink items-center	flex-wrap">
    
            <div class="w-full md:w-6/12 mt-3 section-left-image">
    
                <img :src="img3" alt="" class="w-100">
    
            </div>
    
            <div class="w-full md:w-6/12 section-right-text">
    
                <div class="section-text-container">
    
                    <h1 class="text-3xl font-bold text-white">{{translate('our_team')}}</h1>
    
                    <p class="text-[20px] leading-6 text-white font-light mt-4 text-justify">{{translate('our_team_info')}}</p>
        
                </div>
    
            </div>
    
        </div>
    
    </div>
    
    <div class="block sm:hidden">
    
        <div class="bg-customPink items-center flex-wrap">
    
            <div class="w-full md:w-6/12">
    
                <div class="section-text-container">
    
                    <h1 class="text-3xl font-bold text-white">{{translate('our_team')}}</h1>
    
                    <div class="section-left-image">
    
                        <img :src="img3" alt="" class="w-100">
    
                    </div>
    
                    <div class="content-last">
    
                        <p class="text-[20px] leading-6 text-white font-light mt-4 text-justify ">{{translate('our_team_info')}}</p>
    
    
                    </div>
    
                </div>
    
            </div>
    
        </div>
    
    </div>
     
    <div class="hidden sm:block">
    
        <div class="flex bg-customBlue items-center flex-wrap">
    
            <div class="w-full md:w-6/12">
    
                <div class="section-text-container">
    
                    <h1 class="text-3xl font-bold text-white text-justify ">{{translate('care_in_your_community')}}</h1>
    
                    <p class="mt-4 text-[20px] leading-6 text-white font-light text-justify ">{{translate('our_first_office_info')}}</p>
    
    
    
                    <div class="mapouter mt-10">
    
                        <div class="gmap_canvas">
    
                            <iframe class="gmap_iframe" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=cariño pediatrics cabarete&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
    
                            <a href="https://strandsgame.net/">Strands NYT</a>
    
                        </div>
    
                    </div>
    
                </div>
    
            </div>
    
            <div class="w-full md:w-6/12 mt-3 section-right-image">
    
                <img :src="img4" alt="" class="w-100">
    
            </div>
    
        </div>
    
    </div>
    
    <div class="block sm:hidden">
    
        <div class="bg-customBlue items-center flex-wrap">
    
            <div class="w-full md:w-6/12">
    
                <div class="section-text-container">
    
                    <h1 class="text-3xl font-bold text-white home-text-align">{{translate('care_in_your_community')}}</h1>
    
                    <div class="section-right-image">
    
                        <img :src="img4" alt="" class="w-100">
    
                    </div>
    
                    <div class="content-last">
    
                        <p class="mt-4 text-[20px] leading-6 text-white font-light text-justify ">{{translate('our_first_office_info')}}</p>
    
                    </div>
    
                    <div class="mapouter mt-10">
    
                        <div class="gmap_canvas">
    
                            <iframe class="gmap_iframe" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=cariño pediatrics cabarete&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
    
                            <a href="https://strandsgame.net/">Strands NYT</a>
    
                        </div>
    
                    </div>
    
                </div>
    
            </div>
    
        </div>
    
    </div>    

    <div class="hidden sm:block">
    
        <div class="flex items-center flex-wrap bg-customBrown">
    
            <div class="w-full md:w-6/12 mt-3 section-left-image">
    
                <img :src="img6" alt="" class="w-100">
    
            </div>
    
            <div class="w-full md:w-6/12 section-right-text">
    
                <div class="section-text-container">
    
                    <h1 class="text-3xl font-bold text-white  pt-10">{{translate('donations')}}</h1>

    
                    <p class="text-[20px] leading-6 text-white font-light mt-4 text-justify ">{{translate('donations_info')}}</p>
    
                    <a class="underline text-xl d-block mt-4 pb-10 text-white">
    
                        <router-link to="/Donations">{{translate('read_more')}}</router-link>
    
                    </a>
    
                </div>
    
            </div>
    
        </div>
    
    </div>
    
    <div class="block sm:hidden">
    
        <div class="items-center flex-wrap">
    
            <div class="w-full md:w-6/12">
    
                <div class="section-text-container">
    
                    <h1 class="text-3xl font-bold custom-black">{{translate('donations')}}</h1>
    
                    <div class="section-left-image">
    
                        <img :src="img6" alt="" class="w-100">
    
                    </div>
    
                    <div class="content-last">
    
                        <p class="text-[20px] leading-6 custom-black font-light mt-4 text-justify ">{{translate('donations_info')}}</p>
    
                        <a class="custom-black underline text-xl d-block mt-4">
    
                            <router-link to="/Donations">{{translate('read_more')}}</router-link>
    
                        </a>
    
                    </div>
    
                </div>
    
            </div>
    
        </div>
    
    </div>

    <div class="hidden sm:block">
    
        <div class="flex items-center flex-wrap">

            <div class="w-full md:w-6/12">
    
                <div class="section-text-container">
    
                    <h1 class="text-3xl font-bold custom-black pt-10">{{translate('accessibility')}}</h1>
    
                    <p class="text-[20px] leading-6 custom-black font-light mt-4 text-justify ">{{translate('insurance_info')}}</p>
    
                    <a class="custom-black underline text-xl d-block mt-4 pb-10">
    
                        <router-link to="/Accessibility">{{translate('read_more')}}</router-link>
    
                    </a>
    
                </div>
    
            </div>
    
            <div class="w-full md:w-6/12 mt-3 section-right-image">
    
                <img :src="img5" alt="" class="w-100">
    
            </div>
    
    
        </div>
    
    </div>
    
    <div class="block sm:hidden">
    
        <div class="items-center flex-wrap">
    
            <div class="w-full md:w-6/12">
    
                <div class="section-text-container">
    
                    <h1 class="text-3xl font-bold custom-black home-text-align">{{translate('accessibility')}}</h1>
    
                    <div class="section-right-image">
    
                        <img :src="img5" alt="" class="w-100">
    
                    </div>
    
                    <div class="content-last">
    
                        <p class="text-[20px] leading-6 custom-black font-light mt-4 text-justify ">{{translate('insurance_info')}}</p>
    
                        <a class="custom-black underline text-xl d-block mt-4">
    
                            <router-link to="/Accessibility">{{translate('read_more')}}</router-link>
    
                        </a>
    
                    </div>
    
                </div>
    
            </div>
    
        </div>
    
    </div>
</template>

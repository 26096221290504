<script setup>
import { ref, onMounted} from 'vue';
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue"


function getTribecaMedicationDosage() {

  fetch('https://www.amis-bi.com/dev/tribeca.cfm')
  .then(response => response.text())
  .then(html => {

      const container = document.getElementById('medication_dosage_container');
      container.innerHTML = html;
      document.getElementById('tp-header').style.display="none";
      document.getElementById('tp-footer').style.display="none";
      document.querySelector('.the-new-basics-book').style.display="none";
    
  })
  .catch(error => console.error('Error fetching data:', error));

}

onMounted(() => {
  getTribecaMedicationDosage();
});

</script>

<template>
  <div class="container lg:max-w-screen-md xl:max-w-screen-xl mx-auto">
    <Header />
      <div class="container" id="medication_dosage_container"></div>
    <Footer />
  </div>
</template>
